@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Playfair Display", serif;
  margin: 0;
}

body {
  margin: 0;
  background-color: rgb(18 18 19);
}
