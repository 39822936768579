@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-size: 25px;
  height: 100px;
}

.input {
  font-family: "Playfair Display", serif;
  width: 470px;
  height: 70px;
  font-size: 35px;
  font-weight: semi-bold;
  border: 1px none transparent;
  border-radius: 5px;
  text-align: center;
}

.input:disabled {
  background-color: rgb(176, 170, 170);
}

@media only screen and (max-width: 600px) {
  .input {
    width: 95vw;
    height: 70px;
    font-size: 35px;
    font-weight: semi-bold;
    border: 1px none transparent;
    border-radius: 5px;
    text-align: center;
  }
}
