@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.btnText {
  color: white;
  text-decoration: none;
}

.note {
  margin: 0;
}

.para {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin: 20px 0;
}

.para_down {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin-top: 40px;
}

.rechko {
  font-family: "Playfair Display", serif;
  background-color: transparent;
  color: white;
  font-size: 1.8rem;
  font-weight: 120;
  text-decoration: underline;
  padding: 3px;
}

@media only screen and (max-width: 600px) {
  .para {
    font-size: 17px;
  }

  .para_down {
    margin-top: 40px;
    font-size: 17px;
  }
}
