@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

/* .keyboardContainer {
  height: 200px;
  width: 350px;
}
.keyboardRow {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 8px;
  touch-action: manipulation;
}

.keyboardRow button {
  font-family: sans-serif;
  font-weight: bold;
  border: 0;
  padding: 0;
  height: 58px;
  cursor: pointer;
  background-color: rgb(129, 131, 132);
  color: rgb(215, 218, 220);
  flex-grow: 1;
  text-transform: uppercase;
  margin-right: 6px;
  border-radius: 4px;
  user-select: none;
} */

/* PRELAZ */

.keyboardRows {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-top: 2rem;
}
.row {
  width: auto;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

button {
  font-family: "Playfair Display", serif;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  background: rgb(129 131 132);
  padding: 1rem 0.52rem;
  border: none;
  text-transform: uppercase;
  color: white;
}
.keyCorrect {
  background: #6aaa64;
  color: #fff;
}

.keyPresent {
  background: #c9b458;
  color: #fff;
}

.keyAbsent {
  background: rgb(58 58 60);
  color: #fff;
}
