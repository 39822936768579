@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

.container {
  font-family: "Playfair Display", serif;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
}
.message {
  position: absolute;
  top: 10%;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  background: #787c7e;
  font-size: 16px;
}

.title {
  letter-spacing: 0.2rem;
  font-size: 36px;
  font-weight: 600;
}

.cube-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.cube {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}
.letter {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 32px;
  font-weight: 700;
  width: 60px;
  height: 60px;
  border: 2px solid rgb(58 58 60);
}

.reset-board {
  padding: 5px 5px 5px 20px;
  background: none;
  color: #6aaa64;
  font-size: 38px;
}

.top {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  border-bottom: 1px solid grey;
}

.para {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
}

.cube-row.error {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.correct {
  background: #6aaa64;
  color: #fff;
}

.present {
  background: #c9b458;
  color: #fff;
}

.absent {
  background: rgb(58 58 60);
  color: #fff;
}
