@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: "Playfair Display", serif;
  margin: 0;
}

body {
  margin: 0;
  background-color: rgb(18 18 19);
}

.Form_form__2hemT {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-size: 25px;
  height: 100px;
}

.Form_input__Vfo9P {
  font-family: "Playfair Display", serif;
  width: 470px;
  height: 70px;
  font-size: 35px;
  font-weight: semi-bold;
  border: 1px none transparent;
  border-radius: 5px;
  text-align: center;
}

.Form_input__Vfo9P:disabled {
  background-color: rgb(176, 170, 170);
}

@media only screen and (max-width: 600px) {
  .Form_input__Vfo9P {
    width: 95vw;
    height: 70px;
    font-size: 35px;
    font-weight: semi-bold;
    border: 1px none transparent;
    border-radius: 5px;
    text-align: center;
  }
}

.Button_body__1uW8r {
  font-family: "Playfair Display", serif;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
}

.Button_btn__2b3Dm {
  display: flex;
  justify-content: center;
  width: 469px;
  height: 60px;
  background-color: whitesmoke;
  color: rgba(0, 0, 0, 0.741);
  font-size: 1.4rem;
  border: 1px black solid;
  border-radius: 5px;
  text-decoration: none;
}

.Button_btn__2b3Dm:disabled {
  background-color: #228a47;
  color: white;
}

@media only screen and (max-width: 600px) {
  .Button_btn__2b3Dm {
    width: 95vw;
    height: 60px;
  }
}

.Layout_holy_container__1IIxz {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Layout_logo__Ht6F1 {
  display: flex;
  justify-content: center;
  color: white;
  margin: 0;
  margin-top: 25px;
  font-size: 35px;
  font-family: "Playfair Display", serif;
}

.Layout_lajna__3uzWX {
  margin-top: 25px;
  width: 100vw;
}

.Layout_para1__1hxWq {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  margin-top: 65px;
}

.Layout_cortex_1__2ohH3 {
  font-family: "Playfair Display", serif;
  background-color: transparent;
  text-decoration: underline;
  color: white;
  font-size: 15px;
  padding: 0;
  margin-left: 5px;
}

@media only screen and (max-width: 600px) {
  .Layout_logo__Ht6F1 {
    font-size: 25px;
    margin-top: 18px;
  }

  .Layout_lajna__3uzWX {
    margin-top: 18px;
  }
}

.Paragraph_container__3R-YM {
  font-family: "Playfair Display", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 25px;
}

.Paragraph_para__30_Sp {
  margin: 8px 0;
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .Paragraph_container__3R-YM {
    width: 100vw;
  }

  .Paragraph_para__30_Sp {
    width: 317px;
    text-align: center;
  }
}

/* .paraDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  margin-top: 45px;
} */

.Note_note__3ofXz {
  display: flex;
  justify-content: center;
  color: #ff9494;
  font-size: 15px;
  font-weight: semi-bold;
}

@media only screen and (max-width: 600px) {
  .Note_note__3ofXz {
    font-size: 12px;
    font-weight: semi-bold;
  }
}

.StartPage_btnText__NS6Ot {
  color: white;
  text-decoration: none;
}

.StartPage_note__3aFnM {
  margin: 0;
}

.StartPage_para__3gA39 {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin: 20px 0;
}

.StartPage_para_down__1qLlV {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 20px;
  margin-top: 40px;
}

.StartPage_rechko__25OPX {
  font-family: "Playfair Display", serif;
  background-color: transparent;
  color: white;
  font-size: 1.8rem;
  font-weight: 120;
  text-decoration: underline;
  padding: 3px;
}

@media only screen and (max-width: 600px) {
  .StartPage_para__3gA39 {
    font-size: 17px;
  }

  .StartPage_para_down__1qLlV {
    margin-top: 40px;
    font-size: 17px;
  }
}

.container {
  font-family: "Playfair Display", serif;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;

  align-items: center;
}
.message {
  position: absolute;
  top: 10%;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  background: #787c7e;
  font-size: 16px;
}

.title {
  letter-spacing: 0.2rem;
  font-size: 36px;
  font-weight: 600;
}

.cube-row {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

.cube {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}
.letter {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 32px;
  font-weight: 700;
  width: 60px;
  height: 60px;
  border: 2px solid rgb(58 58 60);
}

.reset-board {
  padding: 5px 5px 5px 20px;
  background: none;
  color: #6aaa64;
  font-size: 38px;
}

.top {
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  border-bottom: 1px solid grey;
}

.para {
  display: flex;
  justify-content: center;
  color: white;
  text-decoration: none;
}

.cube-row.error {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

.correct {
  background: #6aaa64;
  color: #fff;
}

.present {
  background: #c9b458;
  color: #fff;
}

.absent {
  background: rgb(58 58 60);
  color: #fff;
}

/* .keyboardContainer {
  height: 200px;
  width: 350px;
}
.keyboardRow {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto 8px;
  touch-action: manipulation;
}

.keyboardRow button {
  font-family: sans-serif;
  font-weight: bold;
  border: 0;
  padding: 0;
  height: 58px;
  cursor: pointer;
  background-color: rgb(129, 131, 132);
  color: rgb(215, 218, 220);
  flex-grow: 1;
  text-transform: uppercase;
  margin-right: 6px;
  border-radius: 4px;
  user-select: none;
} */

/* PRELAZ */

.Keyboard_keyboardRows__ZtPDD {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  align-items: center;
  margin-top: 2rem;
}
.Keyboard_row__2CItl {
  width: auto;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

button {
  font-family: "Playfair Display", serif;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  background: rgb(129 131 132);
  padding: 1rem 0.52rem;
  border: none;
  text-transform: uppercase;
  color: white;
}
.Keyboard_keyCorrect__1VORb {
  background: #6aaa64;
  color: #fff;
}

.Keyboard_keyPresent__22j9a {
  background: #c9b458;
  color: #fff;
}

.Keyboard_keyAbsent__1prMt {
  background: rgb(58 58 60);
  color: #fff;
}

.Modal_container__39_NS {
  /* background-color: rgb(129 131 132); */
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  border: rgb(129 131 132) transparent solid;
  border-radius: 20px;
  width: 250px;
  height: 300px;
  margin-top: 90px;
  position: absolute;
}

.Modal_para__1zSjo {
  color: black;
  display: flex;
  justify-content: center;
}

.Modal_btn__1NiBb {
  font-family: "Playfair Display", serif;
  display: flex;
  margin: auto;
  background-color: #538d4e;
  border-radius: 9px;
  padding: 20px;
}

.Modal_header__3txAo {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.Modal_close__Uo0a8 {
  border-radius: 50%;
  position: absolute;
  font-size: 25px;
  margin-top: 15px;
  margin-left: 15px;
  cursor: pointer;
}

.Modal_icon__2PdJB {
  width: 65px;
  height: 65px;
  margin-left: 92.5px;
  margin-top: 45px;
}

