@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");

.container {
  font-family: "Playfair Display", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 25px;
}

.para {
  margin: 8px 0;
  font-size: 25px;
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100vw;
  }

  .para {
    width: 317px;
    text-align: center;
  }
}

/* .paraDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
  margin-top: 45px;
} */
